#content {
  transition-timing-function: ease-in;
  transition-duration: 375ms;
}

.pagination {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2rem 0;
  padding: var(--adb-body-padding);
  text-align: center;

  a {
    border: 1px solid var(--adb-brand-color-primary);
    border-radius: 100%;
    display: table;
    font-family: monospace;
    font-size: 75%;
    line-height: 1;
    padding: 1.25em 1.5em;
    margin: 0 0.5rem;
    text-decoration: none;

    &:hover {
      background-color: var(--adb-brand-color-primary);
      color: #fff;
    }

    &.active {
      background: #9a9a9a;
      border-color: #808080;
      color: white !important;
      text-decoration: none;
    }
  }
}

.skrim {
  position: relative;
  // overflow: hidden;

  &::before {
    background: radial-gradient(
      ellipse at center, 
      rgba(2, 129, 197, 0) 0%,
      rgba(0, 0, 0, 0.375) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    z-index: 0;
    mix-blend-mode: soft-light;
  }
}

@media only screen and (max-width: 374.98px) {
  .layout > header .site-title {
    display: none;
  }
}

@media only screen and (min-width: 375px) {
  :root {
    --adb-body-padding: .5em 1em;
  }
}

@media only screen and (max-width: 479.98px) {
  :root {
    --adb-letter-spacing: -.25rem;
  }
}

@media only screen and (min-width: 480px) {
  body {
    font-size: 106.25%;
  }
}

@media only screen and (max-width: 639.98px) {
  .tagline {
    display: none;
  }
  .nav-legal {
    display: block;

    a {
      display: inline-block;
      margin: 0 0.375em;
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .service-asides {
    .wrap {
      // display: flex;
      flex-direction: column !important;

      .w-50 {
        width: 100% !important;
        margin-bottom: 1.625em;

        h4 {
          margin-top: 0;
        }
      }
    }
  }
}


@media only screen and (min-width: 768px) {
  body {
    font-size: 112.5%;
  }
}


@media only screen and (min-width: 1024px) {
  body {
    letter-spacing: var(--adb-letter-spacing);
    font-size: 118.75%;
  }

  .layout {
    min-height: 100vh;
  }
}

// @media only screen and (max-width: 1151.98px) {
//   p {
//     text-align: center;
//   }
// }

@media only screen and (min-width: 1152px) {
  #nav-toggle {
    display: none;
  }

  #responsive-nav {
    background-color: transparent;
    bottom: auto;
    box-shadow: none;
    display: flex !important;
    flex-direction: row;
    height: auto;
    max-width: fit-content;
    padding-right: 0;
    position: relative;
    right: auto;
    top: auto;
    width: auto;

    a {
      color: var(--adb-brand-color-primary);
      margin-bottom: 0;
      padding-left: 1.25em;

      &:hover {
        color: var(--adb-brand-color-secondary);
      }
    }

    button[data-orgname] {
      display: none;
    }

    &.skrim::before {
      content: none;
    }
    
    .nav-inner {
      // display: flex;
      flex-direction: row !important;
      margin-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

@media only screen and (min-width: 1280px) {
  body {
    font-size: 125%;
  }
  main > * {
    max-width: 62rem;
  }
}

@media only screen and (min-width: 1440px) {
  body {
    font-size: 131.25%;
  }
  main > * {
    max-width: 72rem;
  }
}

@media only screen and (min-width: 1600px) {
  body {
    font-size: 137.5%;
  }
}

@media only screen and (min-width: 1800px) {
  html {
    background-image: radial-gradient(
      ellipse at center,
      var(--adb-brand-color-secondary) 0,
      var(--adb-brand-color-secondary) 83.33%
    );
  }
  body {
    border-radius: 1.25em;
    filter: drop-shadow(0 .75em var(--adb-text-shadow-blur) var(--adb-text-shadow-color));
    // font-size: 150%;
    margin: 3.25em auto 6.5em;
    max-width: 1660px;
  }
  main > * {
    max-width: 80rem;
  }
  main {
    > footer,
    > header {
      padding-bottom: .625em;
      padding-top: .625em;
    }
  }
  .layout {
    border-radius: 1.25em;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1920px) {
  body {
    font-size: 143.75%;
  }
}
